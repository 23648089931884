class checkVAT {
    
  // This executes when the function is instantiated.
  constructor(){
      this.links = document.querySelectorAll('.check-vat');
      this.iterateLinks();
  }

  iterateLinks() {
      // If there are no links on the page, stop the function from executing.
      if(this.links.length === 0) return;
      // Loop over each link on the page. A page could have multiple nested forms.
      this.links.forEach((link)=>{
          link.addEventListener('keyup', (e) => {
              this.handleChange(link, e);
          });
      });
  }

  handleChange(link, e) {
      // Stop the function from executing if a link or event were not passed into the function. 
      if (!link || !e) return;
      // Prevent the browser from following the URL.
      e.preventDefault();
      
      let xhr = new XMLHttpRequest();
      if(window.response == null || window.response.country_code + window.response.vat_number != link.value || document.querySelector("input[name='customer[address_line_1]']").value.length == 0) {
        xhr.open('GET', "/vat/"+link.value);
        xhr.send();
      }
      
      xhr.onload = function() {
        if(xhr.status == 200 && xhr.response.length > 1) {
          window.response = JSON.parse(xhr.response)
        }
        nom = document.querySelector("input[name='customer[name]']");
        al1 = document.querySelector("input[name='customer[address_line_1]']");
        pc = document.querySelector("input[name='customer[postal_code]']");
        city = document.querySelector("input[name='customer[city]']");
        country = document.querySelector("select[name='customer[country]']");

        function splitAddress(address) {
          array = address.split(/\r?\n/);
          return array
        }
      
        function parseAddress(address) {
          return splitAddress(address)[0];
        }
      
        function parseCity(address) {
          return splitAddress(address)[1].split(" ").slice(-1)[0]
        }
      
        function parsePostalCode(address) {
          return splitAddress(address)[1].split(" ")[0]
        }

        nom.value = window.response.name;
        country.value = window.response.country_code;
        al1.value = parseAddress(window.response.address);
        pc.value = parsePostalCode(window.response.address);
        city.value = parseCity(window.response.address);
      };
  }

}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work 
window.addEventListener('turbolinks:load', () => new checkVAT() );